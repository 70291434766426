import { Autocomplete, Box, Button, Chip, InputLabel, MenuItem, Modal, Stack, Typography, styled } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import moment from "moment";
import { AppEnum, ParameterTypeValue } from "../../../constants/app-enum";
import '../plugin-parameters/plugin-parameters';
import { isNullOrEmpty } from "../../../services/data-source-validation";
import { useMemo } from "react";
import { autocompleteClasses } from '@mui/material/Autocomplete';
import themeColor from "../../../styles/_exports.module.scss";
import _, { isEmpty } from 'lodash';


export const StyledParamAutocompletePopper = styled('div')(({ theme }) => ({

    [`& .${autocompleteClasses.listbox}`]: {
        fontSize: '13px !important',
        paddingTop: '0px !important',

        [`& .${autocompleteClasses.option}`]: {
            '&[aria-selected="true"]': {
                backgroundColor: `${themeColor.primaryColor} !important`,
                color: `white !important`
            },

            '&[data-focus="true"]': {
                backgroundColor: `${themeColor.primaryColor} !important`,
                color: `white !important`
            },

            '&:hover': {
                backgroundColor: `${themeColor.avatarBackground}`,
                color: `${themeColor.secondaryColor}`
            },
        },
    },

    [`&.${autocompleteClasses.popper}`]: {
        marginTop: '-0.3em',
    },
}));

export const Parameters = (props) => {

    const { parameter, index, dateObject, complexTypeValue, handleOnInputChange, handleMultiSelectedFields, multiSelectedFields, optionValues, state } = props;


    const hasDynamicListOptions = useMemo(() => {
        return state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.length > 0;
    }, [complexTypeValue?.parameterName, state?.dynamicListOptions])

    const memoizedDynamicListValue = useMemo(() => {
        return hasDynamicListOptions
            ? (state.dynamicListOptions[complexTypeValue?.parameterName].filter(option => option.isSelected)[0]?.label || "")
            : "";
    }, [state?.dynamicListOptions, complexTypeValue?.DataCollectionUId]);


    const isUserDefinedVariable = useMemo(() => {
        return parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable;
    }, [parameter.parameterTypeCD])


    const isDataTypeDate = useMemo(() => {
        return parameter?.sysDataTypeId === AppEnum.DataTypeId.Date;
    }, [parameter?.sysDataTypeId])


    const isDataTypeDynamicList = useMemo(() => {
        return parameter?.sysDataTypeId === AppEnum.DataTypeId.DynamicList;
    }, [parameter?.sysDataTypeId])

    const memoizedMultiSelectDropdownInputLabelProps = useMemo(() => ({
        shrink: isDataTypeDynamicList
            ? (state?.dynamicListOptions?.[complexTypeValue?.parameterName]?.filter(option => option.isSelected)?.length > 0 ? true : undefined)
            : (multiSelectedFields?.length > 0 ? true : undefined)
    }), [parameter?.sysDataTypeId, state?.dynamicListOptions, complexTypeValue?.DataCollectionUId, multiSelectedFields]);


    const defaultParameterValue = useMemo(() => {
        if (!parameter?.parameterValue && parameter?.sysDataTypeId === AppEnum.DataTypeId.List) {
            return complexTypeValue?.find((i) => i.Default)?.Value;
        }
        return parameter?.parameterValue;
    }, [parameter?.parameterValue, complexTypeValue]);


    const dynamicListOptions = useMemo(() => {
        const options = state?.dynamicListOptions?.[complexTypeValue?.parameterName] || [];

        return options.map(option => ({
            value: option?.value,
            label: option?.label,
        }));
    }, [state?.dynamicListOptions, complexTypeValue?.parameterName])


    const formattedDateValue = useMemo(() => {
        if (isDataTypeDate) {
            return moment(dateObject).format(AppEnum.DateFormats.Y_M_D);
        }

        // check for null or undefined for other than string 
        return (typeof (parameter?.parameterValue) === 'string' ? isNullOrEmpty(parameter?.parameterValue) : parameter?.parameterValue == null) ? parameter.defaultValue : parameter?.parameterValue;

    }, [isDataTypeDate, dateObject, parameter?.parameterValue, parameter?.defaultValue]);

    const isParameterTypeFixed = useMemo(() => {
        return parameter?.parameterTypeCD === AppEnum.ParameterTypeValue.Fixed;
    }, [parameter?.parameterTypeCD])


    const dynamicListLabel = useMemo(() => {
        const options = state?.dynamicListOptions?.[complexTypeValue?.parameterName] || [];
        const selectedOptions = options.filter(option => option.isSelected);

        return selectedOptions.length > 0
            ? `${parameter?.displayName} (${selectedOptions.length} Selected)`
            : parameter?.displayName;

    }, [state?.dynamicListOptions, complexTypeValue?.parameterName, parameter?.displayName]);


    const selectedValueList = useMemo(() => {

        if (isDataTypeDynamicList && parameter.isMultiSelectList) {
            if (hasDynamicListOptions) {
                return state.dynamicListOptions[complexTypeValue?.parameterName]?.filter(option => option.isSelected) || [];
            }
            return [];
        }
        return multiSelectedFields;

    }, [isDataTypeDynamicList, hasDynamicListOptions, state?.dynamicListOptions, complexTypeValue?.parameterName, multiSelectedFields]);

    const multiSelectOptions = useMemo(() => {

        if (isDataTypeDynamicList) {
            return hasDynamicListOptions
                ? state.dynamicListOptions[complexTypeValue?.parameterName] || []
                : [];
        }

        return optionValues;
    }, [isDataTypeDynamicList, hasDynamicListOptions, state?.dynamicListOptions, complexTypeValue?.parameterName, optionValues]);


    return (
        <div className="filter-box ">
            {isUserDefinedVariable && parameter.isMultiSelectList ?
                <Autocomplete
                    multiple
                    id="tags-filled"
                    className="text-field1 label-text-field"
                    label={parameter?.displayName}
                    options={multiSelectOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedValueList}
                    onChange={((e, newValue) => handleMultiSelectedFields(e, newValue, index, false, false, true))}
                    renderTags={(value, getTagProps) =>
                        <div className='param-multiselect-chip'>
                            {value.map((option, index) => (
                                <Chip
                                    key={option.value}
                                    label={option.label}
                                    {...getTagProps({ index })}
                                    sx={{ margin: '8px 6px 0px 6px !important' }}
                                />
                            ))}
                        </div>
                    }
                    renderOption={(props, option) => (

                        <MenuItem
                            {...props}
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )}
                    renderInput={(params) => (
                        <ASTextField
                            {...params}
                            variant="outlined"
                            label={
                                isDataTypeDynamicList ? dynamicListLabel
                                    :
                                    (multiSelectedFields?.length > 0 || params?.InputProps?.value?.length > 0
                                        ? `${parameter?.displayName} (${multiSelectedFields.length} Selected)` : parameter?.displayName)
                            }
                            InputLabelProps={memoizedMultiSelectDropdownInputLabelProps}
                            InputProps={{
                                ...params.InputProps,
                                sx: {
                                    padding: "0px !important",
                                    '& .MuiAutocomplete-clearIndicator': {
                                        display: 'none',
                                    },
                                    '& .MuiAutocomplete-popupIndicator': {
                                        display: 'none',
                                    },
                                    '& .MuiAutocomplete-input': {
                                        padding: '7.5px 4px 7.5px 9px !important',
                                    }
                                }
                            }}
                            sx={{
                                '& [data-shrink="false"]': {
                                    top: '-9px !important',
                                }
                            }}
                        />
                    )}
                    sx={{ width: "100%" }}
                />
                :
                <>
                    {parameter.parameterTypeCD === AppEnum.ParameterTypeValue.UserInput || isParameterTypeFixed ?
                        < ASTextField
                            key={index}
                            className="text-field1 label-text-field "
                            type={isDataTypeDate ? "date" : parameter?.sysDataTypeId === AppEnum.DataTypeId.Number ? "number" : "text"}
                            fullWidth
                            label={parameter?.displayName}
                            variant="outlined"
                            disabled={isParameterTypeFixed}
                            onChange={(e) => handleOnInputChange(e, index, false)}

                            defaultValue={formattedDateValue}
                            InputLabelProps={{
                                shrink: isDataTypeDate ? true : undefined,
                            }}
                            sx={{
                                '& [data-shrink="false"]': {
                                    top: '-9px !important',
                                }
                            }}
                        /> : null
                    }

                    {
                        isUserDefinedVariable ? parameter?.sysDataTypeId === AppEnum.DataTypeId.List ?
                            < ASTextField
                                size="small"
                                className="text-field1 label-text-field "
                                type={"text"}
                                sx={{ fontSize: "12px" }}
                                fullWidth
                                defaultValue={defaultParameterValue}
                                label={parameter?.displayName}
                                variant="outlined"
                                select={true}
                                SelectProps={{
                                    MenuProps: {
                                        className: "menu-role-div",
                                    },
                                }}
                                onChange={(e) => handleOnInputChange(e, index, true, false)}
                            >
                                {complexTypeValue?.map((list, index) => {
                                    return (
                                        <MenuItem sx={{ fontSize: "12px" }} value={list?.Value} key={`dynamic ${index}`} >
                                            {list.Label}
                                        </MenuItem>
                                    );
                                })}
                            </ASTextField>
                            : <>
                                <div className="default-value-dropdown">
                                    <Stack spacing={2}>
                                        <Autocomplete
                                            size="small"
                                            freeSolo
                                            className="text-field1 label-text-field"
                                            options={
                                                dynamicListOptions
                                            }
                                            renderOption={(props, option) => (
                                                <MenuItem
                                                    {...props}
                                                    key={`option-${option?.value}`}
                                                    className={
                                                        memoizedDynamicListValue === option?.label
                                                            ? "selected-dynamic-opt"
                                                            : "menu-role-div"
                                                    }
                                                >
                                                    {option?.label}
                                                </MenuItem>
                                            )}
                                            onChange={(event, value) => {
                                                const newValue = value ? value?.value : "";
                                                handleOnInputChange({ target: { value: newValue } }, index, true, true);
                                            }}
                                            value={memoizedDynamicListValue}
                                            PopperComponent={StyledParamAutocompletePopper}
                                            renderInput={(params) => (
                                                <ASTextField
                                                    sx={{ fontSize: "12px" }}
                                                    {...params}
                                                    label={parameter?.displayName}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: memoizedDynamicListValue && params.InputProps.endAdornment,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </div>
                            </> : null
                    }
                </>}
        </div >
    )
}