import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DataCollectionsState } from '../../data-collections/data-collections-state'
import { ALL } from '../../data-collections/data-collections-controller'
import _ from 'lodash';
import SearchOrFilterVariablesHeader from '../../../components/connections/variables-list/search-or-filter-variables-header.jsx';
import { getFilteredVariableList } from './variables-controller';


export const getVariableFilterFromStorage = () => {
    return localStorage.getItem("UserDefinedVariableFilter");
}

const SearchOrFilterVariablesHeaderController = (props) => {

    const { userDefinedState, setUserDefinedState, connectionList, templateCompany } = props;

    const [selectedConnectionValue, setSelectedConnectionValue] = useState(getVariableFilterFromStorage() || ALL);
    const [isShrunk, setIsShrunk] = useState(false);

    const searchInputRef = useRef('');

    const filterHeader = useMemo(() => [(new DataCollectionsState()).filterHeader[0]], []);

    useEffect(() => {
        const currentConnectionFilter = getVariableFilterFromStorage()

        if (connectionList?.length && currentConnectionFilter) {
            let selectedFilter = connectionList.find(i => i.uId === currentConnectionFilter);
            setSelectedConnectionFilter((currentConnectionFilter !== ALL && Boolean(selectedFilter)) ? selectedFilter.uId : ALL);
        }

    }, [connectionList])



    const onSelectedConnectionChange = (value) => {
        let currentConnectionFilter = getVariableFilterFromStorage();

        if (currentConnectionFilter === value) {
            return
        }
        setSelectedConnectionFilter(value)
    };

    const setSelectedConnectionFilter = (value) => {
        setSelectedConnectionValue(value);
        localStorage.setItem("UserDefinedVariableFilter", value);
    }


    const filterUserDefinedVariables = (selectedFilter) => {

        onSelectedConnectionChange(selectedFilter.uId);

        if (searchInputRef.current?.value) {
            onChangeSearchUserVariableHandler(searchInputRef.current?.value, selectedFilter.uId);
        }
        else {
            let filteredUsers = getFilteredUserDefinedVariables(selectedFilter);
            setUserDefinedState((prevState) => ({ ...prevState, filteredUsers }));
        }
    }


    const getFilteredUserDefinedVariables = (selectedFilter) => {

        if (selectedFilter.uId === ALL) {
            return userDefinedState.users
        }
        let filteredUsers = getFilteredVariableList(selectedFilter, userDefinedState.users, templateCompany);

        return filteredUsers;
    }


    const onFocusSearchBoxHandler = () => {
        setIsShrunk(true);
    }



    const getFilteredUsers = (preFilteredUsers, searchKey) => {
        return _.filter(preFilteredUsers, (user) =>
            user?.name?.toLowerCase().trim().includes(searchKey))
    }


    useEffect(() => {
        if (userDefinedState.users?.length) {
            const currentConnectionFilter = getVariableFilterFromStorage()
            onChangeSearchUserVariableHandler(searchInputRef.current?.value, currentConnectionFilter);
        }
    }, [userDefinedState.users])



    const onChangeSearchUserVariableHandler = (searchedKey, connectionValue) => {

        let currentSelectedConnectionValue = connectionValue ?? selectedConnectionValue;

        // to handle if user clears the input from close icon
        if (searchedKey === null) {
            setIsShrunk(false);
        }
        const searchKey = searchedKey?.toLowerCase().trim();

        const isShowAllFilterSelected = (currentSelectedConnectionValue === ALL);
        let filteredUsers = []

        if (isShowAllFilterSelected)
            filteredUsers = userDefinedState.users
        else {
            let selectedFilter = connectionList.find(i => i.uId === currentSelectedConnectionValue);
            filteredUsers = getFilteredUserDefinedVariables(selectedFilter);
        }

        if (searchKey) {
            filteredUsers = getFilteredUsers(filteredUsers, searchKey);
        }
        else {
            searchInputRef.current.value = '';
        }
        setUserDefinedState((prevState) => ({ ...prevState, filteredUsers }));
    }

    const onChangeSearchCollectionDebouncedHandler = _.debounce(onChangeSearchUserVariableHandler, 300)



    const onBlurSearchBoxHandler = (event) => {

        if (!event.target.value?.length)
            setIsShrunk(false);
    }

    const connectionMenuList = useMemo(() => {

        return [{ uId: ALL, name: ALL }, ...(
            _.uniqBy(connectionList, function (connection) {
                return connection?.name;
            })
            || [])];

    }, [connectionList])



    return (
        <SearchOrFilterVariablesHeader
            filterHeader={filterHeader}
            onSelectedConnectionChange={onSelectedConnectionChange}
            selectedConnectionValue={selectedConnectionValue}
            filterUserDefinedVariables={filterUserDefinedVariables}
            onFocusSearchBoxHandler={onFocusSearchBoxHandler}
            onBlurSearchBoxHandler={onBlurSearchBoxHandler}
            onChangeSearchUserVariableHandler={onChangeSearchCollectionDebouncedHandler}
            searchInputRef={searchInputRef}
            isShrunk={isShrunk}
            connectionMenuList={connectionMenuList}
        />
    )
}

export default SearchOrFilterVariablesHeaderController