export const formValidationCheck = (_formObject, isFormInvalid) => {
    let formInvalid = false;

    for (let key in _formObject) {
        if (!_formObject[key].isValid) {
            formInvalid = true;
            break;
        }
    }

    isFormInvalid(formInvalid);
};

export const handleChangeInputValidation = (dataSource, event, formData, saveFormData, datasourceTypeParameters, isFormInvalid) => {
    const obj = datasourceTypeParameters?.find(
        (type) => type.id === dataSource.id
    );

    if (obj) {
        formData[obj.parameterType] = event;
    } else {
        formData.name = event;
    }

    saveFormData({ ...formData });
    formValidationCheck(formData, isFormInvalid);
};


export const isNullOrEmpty = (str) => {
    // if str is not string then trim will throw error
    return str === null || str === undefined || (typeof str === 'string' && str.trim() === '');
};