import { Box, Divider, IconButton, InputAdornment, MenuItem, Paper, styled, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { muiCheckboxStyle } from '../../user-permission/user-permission';
import { ASTextField } from "../../../shared/as-text-fields";
import Checkbox from '@mui/material/Checkbox';
import './select-columns.scss';
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import themeColor from "../../../styles/_exports.module.scss";
import { ASButton } from "../../../shared/as-button/as-button";
import { columnHeaderRowCells } from "../../../controllers/data-collections/add-new-data-collection/select-columns/select-columns-controller";
import { tableBorderRight } from "../../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { selectedMenuItemBackgroundColor } from "../collection-parameters/collection-parameters";
import CloseIcon from '@mui/icons-material/Close';
import ExpandableView from "../../common/expandableView/expandableView";
import React from 'react';
import { useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import MuiDialog from "../../../shared/mui-dialog/mui-dialog";
import { AppEnum } from "../../../constants/app-enum";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// styled selected field for display format
export const FormatSelectField = styled(TextField)({
    "& label.Mui-focused": {
        color: themeColor.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
        height: '34px',
        color: themeColor.mutedColor,
        "&.Mui-focused fieldset": {
            borderColor: themeColor.primaryColor,
        },
    },
});

const SelectColumns = (props) => {

    const { addNewColumn, deleteManualColumn, displayFormatDataTypes, isRest, closeDialog, onDragEnd } = props;
    const { columnSchemaError, columnSchemaList, collectionColumns, displayFormatList, sortedColumnList, isShowDeletePopUp } = props.state;
    const { collectionUId: collectionUId } = useParams();
    const isExpandedView = useSelector((state) => state.asState.isExpandedView);

    return (
        <div ref={props.prevStepRef}>
            <Box sx={{ width: '1250px' }}>
                <Paper sx={{ mb: 2, padding: "20px", paddingBottom: "0px" }} className='column-table-paper'>
                    <ExpandableView>
                        {
                            columnSchemaError &&
                            <div className="collection-error-div">
                                <MessageToaster errorMessage={columnSchemaError} />
                            </div>
                        }
                        <div className="search-textfield">
                    {props?.state?.sysCollectionTypeId != AppEnum.SysCollectionTypeId.SQL_SERVER && <IconButton
                                disableRipple
                                sx={{ color: themeColor.primaryButton, }}
                                onClick={(event) => {
                                    event.stopPropagation(); 
                                    addNewColumn();
                                }}
                            >
                                <AddCircleIcon sx={{ fontSize: '2rem' }} />

                                <Typography
                                    sx={{ marginLeft: "5px", color: themeColor.text_2, }}
                                    variant="body1" display="block"
                                >
                                    Add Column
                                </Typography>
                            </IconButton>}
                            <span></span>
                            <ASTextField
                                className="search-text-field"
                                fullWidth
                                label="Search"
                                key="Search"
                                variant="outlined"
                                onChange={(event) => {
                                    setTimeout(() => {
                                        props.onChangeSearchColumnHandler(event.target.value);
                                    }, 100)
                                }}
                                inputRef={props.searchInputRef}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <CloseIcon className="clear-icon" onClick={() => props.onChangeSearchColumnHandler(null)} />
                                    </InputAdornment>,
                                }}
                            />
                        </div>

                        <Divider />

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <TableContainer  {...provided.droppableProps} ref={provided.innerRef} sx={{ overflowX: 'unset', height: columnSchemaList?.length === 0 ? 'auto' : isExpandedView ? 'calc(100vh - 185px)' : 'calc(100vh - 325px)', overflowY: 'auto' }} >
                                        <Table
                                            sx={{ minWidth: 750 }}
                                            aria-labelledby="tableTitle"
                                            size='medium'
                                        >
                                            <TableHead>

                                                <TableRow>
                                                    <TableCell>
                                                    </TableCell>
                                                    <TableCell padding="checkbox" className="custom-table-cell">

                                                        <Checkbox
                                                            color="primary"
                                                            checked={columnSchemaList?.length === collectionColumns?.length}
                                                            onChange={(event) => props.onCheckAllColumnHandler(event)}
                                                            sx={muiCheckboxStyle}
                                                        //disabled={collectionUId ? true : false}
                                                        />
                                                    </TableCell>
                                                    {

                                                        (!props.isTemplateCompany ? columnHeaderRowCells?.filter(i => !i.isTemplateColunm) : columnHeaderRowCells).map((headCell, index) => {
                                                            return (

                                                                <TableCell
                                                                    key={headCell.id}
                                                                    align='left' scope="row" component={'th'}
                                                                    sx={(index > 6) ? { borderRight: 'none' } : tableBorderRight}
                                                                    className={index === 0 ? "name-cell" : ''}
                                                                    id={`enhanced-table-checkbox-${index}`}
                                                                    padding={index === 0 ? "none" : 'normal'}
                                                                >
                                                                    {headCell.label}
                                                                </TableCell>

                                                            )
                                                        })
                                                    }
                                                </TableRow>

                                            </TableHead>

                                            <TableBody>
                                                {
                                                    (props.searchInputRef?.current?.value ? sortedColumnList : columnSchemaList)?.map((column, index) => {

                                                        let formatId = displayFormatList.filter(i => (i.displayFormat ===
                                                            (column?.displayFormat ? column.displayFormat : column?.DisplayFormat) && (i.dataBaseDataType == column?.ColumnDataType)))[0]?.id;
                                                        let dataytypeDisplayFormates = displayFormatList.filter(i => i.dataBaseDataType == column?.ColumnDataType);
                                                        let listDataTypeValue = displayFormatDataTypes?.filter(i => i.sysDataTypeId == column?.dataType)[0];
                                                        formatId = formatId || '';

                                                        return (
                                                            <Draggable key={index} draggableId={`param-${index}`} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={-1}
                                                                        key={`column-schema-list ${index}`}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        className={`dragged-row ${snapshot.isDragging ? 'dragged' : ''}`}
                                                                    // selected={column?.isSelected}
                                                                    >
                                                                        <TableCell className="drag-icon-table-cell">
                                                                            <IconButton
                                                                                {...provided.dragHandleProps}
                                                                                aria-label="drag handle"
                                                                                disabled={props.searchInputRef?.current?.value}
                                                                            >
                                                                                <DragHandleIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell className={`custom-table-cell ${column?.isSelected ? 'row-selected' : ''}`}>
                                                                            {!column?.isManuallyCreated ?
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={column?.isSelected ? true : false}
                                                                                    //disabled={collectionUId ? true : false}
                                                                                    onChange={() => props.onCheckColumnHandler(!column?.isSelected, index, column)}
                                                                                    sx={muiCheckboxStyle}
                                                                                /> :
                                                                                     <IconButton onClick={() => deleteManualColumn(index,column)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>}
                                                                        </TableCell>

                                                                        <TableCell
                                                                            className={`custom-table-cell ${column?.isSelected ? 'row-selected name-cell' : 'name-cell'}`}
                                                                            id={`colCheckbox${index}`}
                                                                            scope="row"
                                                                            padding="none"
                                                                            sx={tableBorderRight}
                                                                        >
                                                                            {!column?.isManuallyCreated ?
                                                                                <>{column?.columnName ? column?.columnName : column?.ColumnName}</>
                                                                                :
                                                                                <div className='edit-column-cell' onClick={(event) => {
                                                                                    if (event)
                                                                                        event.stopPropagation();
                                                                                }}
                                                                                >
                                                                                    <ASTextField
                                                                                        className="display-name-input"
                                                                                        onChange={(e) => props.editManualColumnName(e, index, column)}
                                                                                        value={column?.columnName ? column?.columnName : column?.ColumnName}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell align="left" sx={tableBorderRight} className="custom-table-cell">
                                                                            {
                                                                                <FormatSelectField
                                                                                    className="display-format"
                                                                                    //disabled={(collectionUId && !column?.isSelected) ? true : false}
                                                                                    key="Select format"
                                                                                    variant="outlined"
                                                                                    SelectProps={{
                                                                                        MenuProps: {
                                                                                            className: 'display-format-select', sx: selectedMenuItemBackgroundColor
                                                                                        }
                                                                                    }}
                                                                                    defaultValue={""}
                                                                                    disabled={!column?.isSelected}
                                                                                    select={true}
                                                                                    onClick={(event) => {
                                                                                        if (event)
                                                                                            event.stopPropagation();
                                                                                    }}
                                                                                    onChange={(event) => {
                                                                                        if (event)
                                                                                            event.stopPropagation();

                                                                                        props.onSelectDataTypeHandler(index, event.target.value, column)
                                                                                    }}
                                                                                    value={column?.dataType || column?.DataType || ""}
                                                                                >
                                                                                    {(displayFormatDataTypes)?.map((format, formatIndex) => (
                                                                                        <MenuItem
                                                                                            key={`${index}+${formatIndex}`}
                                                                                            value={format.sysDataTypeId}
                                                                                        >
                                                                                            {/* {format?.dataBaseDataType} */}
                                                                                            {format?.dataBaseDataType == 'Int64' ? 'Integer' : format?.dataBaseDataType}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </FormatSelectField>}
                                                                        </TableCell>

                                                                        <TableCell align="left" sx={tableBorderRight} className="custom-table-cell">
                                                                            <FormatSelectField
                                                                                className="display-format"
                                                                                //disabled={(collectionUId && !column?.isSelected) ? true : false}
                                                                                key="Select format"
                                                                                variant="outlined"
                                                                                SelectProps={{
                                                                                    MenuProps: {
                                                                                        className: 'display-format-select', sx: selectedMenuItemBackgroundColor
                                                                                    }
                                                                                }}
                                                                                defaultValue={""}
                                                                                select={true}
                                                                                onClick={(event) => {
                                                                                    if (event)
                                                                                        event.stopPropagation();
                                                                                }}
                                                                                onChange={(event) => {
                                                                                    if (event)
                                                                                        event.stopPropagation();

                                                                                    props.onSelectDisplayFormatHandler(index, event.target.value, column)
                                                                                }}
                                                                                value={column?.isSelected ? formatId : ''}
                                                                            >
                                                                                {(dataytypeDisplayFormates ? dataytypeDisplayFormates : displayFormatList)?.map((format, formatIndex) => (
                                                                                    <MenuItem
                                                                                        key={`${index}+${formatIndex}`}
                                                                                        value={format.id}
                                                                                    >
                                                                                        {format?.displayFormat}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </FormatSelectField>
                                                                        </TableCell>

                                                                        <TableCell sx={tableBorderRight} align="left" className="custom-table-cell">
                                                                            <div className='edit-column-cell' onClick={(event) => {
                                                                                if (event)
                                                                                    event.stopPropagation();
                                                                            }}
                                                                            >
                                                                                <ASTextField
                                                                                    className="display-name-input"
                                                                                    onChange={(e) => props.editDisplayName(e, index, column)}
                                                                                    value={column?.displayName}
                                                                                />
                                                                            </div>
                                                                        </TableCell>

                                                                        {props?.isTemplateCompany && (
                                                                            <>
                                                                                <TableCell sx={tableBorderRight} align="left" className="custom-table-cell">
                                                                                    <Checkbox
                                                                                        color="primary"
                                                                                        checked={column?.isFilterable}

                                                                                        onClick={(event) => {
                                                                                            if (event)
                                                                                                event.stopPropagation();
                                                                                        }}
                                                                                        onChange={(event) => {
                                                                                            event.stopPropagation();  // Stop event propagation to prevent row click
                                                                                            props.onCheckFilterableHandler(event.target.checked, index, column);
                                                                                        }}
                                                                                        sx={{ ...muiCheckboxStyle }}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell sx={tableBorderRight} align="left" className="custom-table-cell">
                                                                                    <Checkbox
                                                                                        color="primary"

                                                                                        checked={column?.isSortable}
                                                                                        onClick={(event) => {
                                                                                            if (event)
                                                                                                event.stopPropagation();
                                                                                        }}
                                                                                        onChange={(event) => {
                                                                                            event.stopPropagation();  // Stop event propagation to prevent row click
                                                                                            props.onCheckSortableHandler(event.target.checked, index, column);
                                                                                        }}
                                                                                        sx={muiCheckboxStyle}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell sx={tableBorderRight} align="left" className="custom-table-cell">
                                                                                    <Checkbox
                                                                                        color="primary"

                                                                                        checked={column?.isDefault}
                                                                                        onClick={(event) => {
                                                                                            if (event)
                                                                                                event.stopPropagation();
                                                                                        }}
                                                                                        onChange={(event) => {
                                                                                            event.stopPropagation();  // Stop event propagation to prevent row click
                                                                                            props.onCheckDefaultHandler(event.target.checked, index, column);
                                                                                        }}
                                                                                        sx={muiCheckboxStyle}
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell align="left" className="custom-table-cell">
                                                                                    <Checkbox
                                                                                        color="primary"

                                                                                        checked={column?.notQuerable}
                                                                                        onClick={(event) => {
                                                                                            if (event)
                                                                                                event.stopPropagation();
                                                                                        }}
                                                                                        onChange={(event) => {
                                                                                            event.stopPropagation();  // Stop event propagation to prevent row click
                                                                                            props.onCheckQuerableHandler(event.target.checked, index, column);
                                                                                        }}
                                                                                        sx={muiCheckboxStyle}
                                                                                    />
                                                                                </TableCell>
                                                                            </>
                                                                        )}
                                                                    </TableRow>
                                                                )}

                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                                 {provided.placeholder && <TableRow className="drag-placeholder" />}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {isExpandedView && <MuiDialog
                            isShowPopUp={isShowDeletePopUp}
                            secondaryButtonAction={closeDialog}
                            primaryButtonAction={deleteManualColumn}
                            closeDialog={closeDialog}
                            secondaryButtonTitle="No"
                            primaryButtonTitle="Yes"
                            titleDescription="Are you sure you want to delete the column?"
                        />}
                    </ExpandableView>
                </Paper>
            </Box>
        </div>
    );
}

export default SelectColumns;
